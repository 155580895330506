<template>
  <div id="page-user-list">
    <vs-button color="success" type="filled"
               :to="{name: 'packages.departures.create', params: { packageId: $route.params.packageId }}" icon="add"
               class="mb-5">
      Create
    </vs-button>
    <div class="vx-card p-6 vs-con-loading__container" id="div-with-loading">
      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="departures.data"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
        @filter-changed="onFilterChanged">
      </ag-grid-vue>
      <vs-pagination :total="departures.pagination.last_page" :value="departures.pagination.current_page" @input="paginate({page: $event})"/>
    </div>
  </div>
</template>
<script>
import {AgGridVue} from "ag-grid-vue";
import loaderDivMixin from "@/mixins/loaderDivMixin";
import CellRendererActions from "./cell-render/CellRendererActions.vue";
import CellRendererBoolean from "./cell-render/CellRendererBoolean.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    AgGridVue,
    CellRendererActions,
    CellRendererBoolean
  },
  mixins: [loaderDivMixin],
  computed: {
    packageId() {
      return this.$route.params.packageId;
    },
    ...mapGetters({
      departures: 'main_package/getPackageDepartures',
    }),
  },

  methods: {
    ...mapActions('main_package', [
      'fetchAndSetPackageDepartures',
    ]),
    paginate(page) {
      this.page = page.page;
      this.fetchPackageDepartures();
    },
    onFilterChanged($event) {
      this.fetchPackageDepartures();
    },
    fetchPackageDepartures() {
      this.openLoading();
      this.fetchAndSetPackageDepartures(this.packageId)
        .then((res) => this.closeLoading())
        .catch((error) => this.closeLoading());
    }
  },

  data() {
    return {
      page: 1,
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          width: 125,
          filter: true,
          filterParams: {
            clearButton: true,
            debounceMs: 200,
            newRowsAction: 'keep'
          }
        },
        {
          headerName: 'Departure date',
          field: 'departure_date',
          filter: true,
          width: 200,
          filterParams: {
            clearButton: true,
            debounceMs: 200,
            newRowsAction: 'keep'
          }
        },
        {
          headerName: 'Discount Percent',
          field: 'discount_percent',
          width: 150,
        },
        {
          headerName: 'Offer Title',
          field: 'offer_title',
          width: 200,
        },
        {
          headerName: 'Status',
          field: 'status',
          width: 150,
          cellRendererFramework: 'CellRendererBoolean'
        },
        {
          headerName: 'Actions',
          field: 'transactions',
          // width: 150,
          cellRendererFramework: 'CellRendererActions',
        },
      ],

      // Cell Renderer Components
      components: {
        CellRendererActions,
        CellRendererBoolean
      }
    }
  }
}
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>



