<template>
  <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
    <!--    <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord"/>-->
    <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord"/>
  </div>
</template>

<script>

import {mapActions} from 'vuex';
import loaderMixin from '../../../../../mixins/loaderMixin';

export default {
  mixins: [loaderMixin],
  name: 'CellRendererActions',
  computed: {
    getPackageId() {
      return this.$route.params.packageId;
    },
  },
  methods: {
    ...mapActions('main_package', [
      'deletePackageDepartureAction',
      'fetchAndSetPackageDepartures'
    ]),
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm Delete`,
        text: `You are about to delete record with id: "${this.params.data.id}"`,
        accept: this.deleteRecord,
        acceptText: "Delete"
      })
    },
    deleteRecord() {
      this.openLoading();
      this.deletePackageDepartureAction({
        packageId: this.getPackageId,
        departureId: this.params.data.id
      }).then(() => this.deletedSuccessfully()).catch((error) => this.closeLoading());
    },
    deletedSuccessfully() {
      this.fetchAndSetPackageDepartures(this.getPackageId)
        .then(() => this.closeLoading()).catch((error) => this.closeLoading());
      this.$vs.notify({
        color: 'success',
        title: 'Package Departure Deleted',
        position: 'top-right',
        text: 'The selected Package Departure was successfully deleted'
      })
    }
  }
}
</script>
